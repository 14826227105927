const logoMain = require('./logo_main.png');
const logoWhite = require('./logo_white.png');

const homeBanner =  require('./mocalaka-banner.png');

const happyNewYear1 = require('./happy_new_year.png')
const happyNewYear2 = require('./happy_new_year_2.png')

const googlePlayBadge = require('./google-play-badge.png');
const appleStoreBadge = require('./app-store-badge.png');

// cool cat
const coolCatLogo = require('./coolcat_logo.png');

// noled
const noLedLogo = require('./noled_logo.png');

const iconYoutube = require('./icon_youtube.webp');

const Images = {
    logoMain,
    logoWhite,
    homeBanner,
    happyNewYear1,
    happyNewYear2,
    coolCatLogo,
    noLedLogo,
    googlePlayBadge,
    appleStoreBadge,
    iconYoutube
}

export default Images;
import { useEffect, useState } from "react";
import Images from "../../../../assets/images";
import Videos from "../../../../assets/videos";
import Layout from "../../../../components/layout"
import './styles.scss';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';

const Home = () => {
    const [value, setValue] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Layout appBarHome={true}>
            <div className="home">
                <div className="banner-image" style={{
                    backgroundImage: `url(${Images.homeBanner})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }} ></div>
                <div className="main-content">
                    <div className="intro-text">
                        <h3>Chào mừng bạn đến với Mocalaka Studio</h3>
                        <p>Studio phần mềm cá nhân, phát triển các ứng dụng sáng tạo</p>
                        <Clock className='homeClock' value={value} />
                        <a href='https://studio3d.mocalaka.vn?room=1'>Tham quan studio 3D</a>
                    </div>
                    <div className="latest-release">
                        <h3>Ra mắt mới nhất</h3>
                        <a className="latest-release-content" href="/ung-dung/cool-cat">
                            <img className="logo" src={Images.coolCatLogo} />
                            <div className="app-info">
                                <h4>Cool Cat - Animation Maker</h4>
                                <p>Ứng dụng tạo hoạt họa</p>
                            </div>
                        </a>
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', maxWidth: '100%' }}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/DfF0qXYeRnE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default Home;
import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';

const CoolCatUserLeaveDone = () => {

    return (
        <Layout>
            <div className="coolcat-user-leave-done">
                <div className="app-info">
                    <img className="logo" src={Images.coolCatLogo} />
                    <h2>Cool Cat - Animation Maker</h2>
                </div>

                <div className="main-content">
                    <h1>Đã xóa tài khoản của bạn!</h1>
                    <p>
                        Cảm ơn bạn đã dành thời gian sử dụng ứng dụng <br />
                        Cool Cat - Animation Maker!
                    </p>

                </div>
            </div>
        </Layout>
    )
}

export default CoolCatUserLeaveDone;
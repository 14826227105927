import { useEffect, useState } from "react";
import Images from "../../../../assets/images";
import Layout from "../../../../components/layout"
import './styles.scss';

const PromotionScreen = () => {

    const data: any = [
        {
            period: "từ 15/05/2023 (chưa xác định ngày hết hạn)",
            title: "Giảm 15% giá trị Hợp đồng, Đơn hàng khi mua dịch vụ tại Viettel IDC.",
            content: (
                <p>
                    Sử dụng Voucher Code: <b style={{ color: 'red' }}>Invi_IDC230520_7677</b>, bạn sẽ được <b>giảm 15%</b> giá trị Hợp đồng, Đơn hàng khi mua dịch vụ (<b style={{color: 'red'}}>*</b>) tại <a href="https://viettelidc.com.vn/">Viettel IDC</a>.<br/>
                    (<b style={{color: 'red'}}>*</b>) 10 Dịch vụ áp dụng: Cloud Server, Virtual Private Cloud, StartDB, Cloud Backup, Cloud Object Storage, Cloud File Storage, Drive, Cloud Camera, Cloud PC, Cloud Desktop
                </p>
            )
        }
    ]

    return (
        <Layout>
            <div className="promotion-screen">
                <div className="main-content">
                    <h2 style={{ marginBottom: '20px' }}>Thông tin khuyến mãi, giảm giá</h2>
                    {data.length ? data.map((item: any) => {
                        return (
                            <div className="promotion-item">
                                <h4>{item.title}</h4>
                                <p>Thời gian: {item.period}</p>
                                {item.content}
                            </div>
                        )
                    }) : <p>Hiện tại không có thông tin khuyến mãi nào</p>}

                </div>
            </div>
        </Layout>
    )
}

export default PromotionScreen;
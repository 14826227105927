import Layout from "../../../../components/layout"

const NotFound = () => {
    return (
        <Layout>
            <p style={{ textAlign: 'center', marginTop: 10 }}>Trang bạn tìm không tồn tại!</p>
        </Layout>
    )
}

export default NotFound;
import { useRef } from "react";
import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';
import axios from "axios";

const CoolCatUserLeave = () => {
    const emailRef = useRef<HTMLInputElement>(null);

    const sendLeaveRequest = async (email: string) => {
        try {
            const res = await axios.post(process.env.REACT_APP_COOL_CAT_API_ENDPOINT + '/api/user/request/leave', { email });
            alert('Đã gửi yêu cầu, vui lòng kiểm tra hộp thư email.');
        } catch (error) {
            alert('Có lỗi xảy ra, vui lòng thử lại.')
        }
    }

    return (
        <Layout>
            <div className="coolcat-user-leave">
                <div className="app-info">
                    <img className="logo" src={Images.coolCatLogo} />
                    <h2>Cool Cat - Animation Maker</h2>
                </div>

                <div className="main-content">
                    <h1>Hướng dẫn xóa tài khoản</h1>
                    <p>
                        Khi bạn thực hiện thao tác xóa tài khoản, toàn bộ thông tin và dữ liệu của bạn trên hệ thống sẽ bị xóa hoàn toàn bao gồm:<br />
                        <ul>
                            <li>Thông tin đăng nhập bao gồm: email, mật khẩu, ảnh đại diện, biệt danh</li>
                            <li>Tất cả các file dự án bạn đã tạo và tải lên hệ thống</li>
                            <li>Tất cả bình luận, yêu thích của bạn trong chức năng cộng đồng</li>
                        </ul>
                        Các dữ liệu bạn tạo ra nằm trên thiết bị của bạn không bị ảnh hưởng.
                    </p>
                    <p>
                        <i>* Lưu ý: nếu bạn đã đăng ký premium, bạn cần tự thực hiện thủ công thao tác hủy đăng ký trên kho ứng dụng.</i>
                    </p>
                    <p>
                        Để thực hiện xóa tài khoản, vui lòng nhập địa chỉ email dùng để đăng nhập ứng dụng.<br />
                        Một email sẽ được gửi cho bạn, trong đó chứa đường dẫn để thực hiện xóa tài khoản của bạn.<br />
                        Hãy cân nhắc kỹ trước khi click vào đường dẫn bởi vì yêu cầu xóa tài khoản sẽ được thực hiện ngay lập tức và không thể đảo ngược.<br /><br />
                        <input
                            ref={emailRef}
                            placeholder="Email của bạn"
                            style={{ marginRight: 5 }}
                        />
                        <button
                            onClick={() => {
                                const email = emailRef.current?.value.trim();
                                email && sendLeaveRequest(email);
                            }}
                        >Yêu cầu xóa tài khoản</button>
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default CoolCatUserLeave;
import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';

const NoLedHomeScreen = () => {
    return (
        <Layout>
            <div className="noled-home-screen">
                <div className="app-info">
                    <img className="logo" src={Images.noLedLogo} />
                    <div>
                        <h2>NoLED</h2>
                        <p>Ứng dụng giả đèn led thông báo cho màn hình OLED</p>
                    </div>
                </div>

                <div className="main-content">
                    <h3>Giới thiệu</h3>
                    <p>
                        Ứng dụng này rất đơn giản, giả lập đèn LED thông báo cho những máy không có đèn LED.
                        <br />
                        Có thể chỉ một số ít người có nhu cầu này, do thiết bị sử dụng trước đó có đèn LED và họ đã quen với điều này.
                        <br />
                        Ứng dụng sử dụng tối ưu nhất cho thiết bị có màn hình OLED.
                    </p>
                    <p>
                        Một số tính năng:
                        <ul>
                            <li>
                                Hiển thị LED tròn hoặc biểu tượng ứng dụng
                            </li>
                            <li>
                                Tùy chỉnh màu sắc và kích thước LED
                            </li>
                            <li>
                                Hiển thị vị trí LED ngẫu nhiên hoặc cố định
                            </li>
                            <li>
                                LED thông báo trạng thái sạc pin
                            </li>
                        </ul>
                    </p>

                    <h3>Liên kết</h3>

                    <div className="doc-link">
                        <a href="https://noled.flycricket.io/privacy.html">Chính sách quyền riêng tư</a>
                    </div>

                    <div className="store-link">
                        <a href="https://play.google.com/store/apps/details?id=kai.anhld.noled"><img src={Images.googlePlayBadge} /></a>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NoLedHomeScreen;
import { useEffect, useState } from "react";
import Images from "../../../../assets/images";
import Layout from "../../../../components/layout"
import './styles.scss';

const AppsScreen = () => {

    return (
        <Layout>
            <div className="apps-screen">
                <div className="main-content">
                    <div className="app-categories">
                        <div className="app-category-detail">
                            <h2>Ứng dụng di động</h2>

                            <div className="app-list">
                                <a className="app-item" href="/ung-dung/cool-cat">
                                    <img className="logo" src={Images.coolCatLogo} />
                                    <div className="app-info">
                                        <h4>Cool Cat - Animation Maker</h4>
                                        <p>Ứng dụng tạo hoạt họa</p>
                                    </div>
                                </a>

                                <a className="app-item" href="/ung-dung/no-led">
                                    <img className="logo" src={Images.noLedLogo} />
                                    <div className="app-info">
                                        <h4>NoLED</h4>
                                        <p>Ứng dụng giả đèn led thông báo cho màn hình OLED</p>
                                    </div>

                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default AppsScreen;
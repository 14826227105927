import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';

const CoolCatHomeScreen = () => {
    return (
        <Layout>
            <div className="coolcat-home-screen">
                <div className="app-info">
                    <img className="logo" src={Images.coolCatLogo} />
                    <div>
                        <h2>Cool Cat - Animation Maker</h2>
                        <p>Ứng dụng tạo hoạt họa</p>
                    </div>
                </div>

                <div className="main-content">
                    <h3>Giới thiệu</h3>
                    <p>
                        Ứng dụng vẽ và tạo video hoạt hình đơn giản trên điện thoại của bạn.
                    </p>
                    <p>
                        BẠN TRONG VAI TRÒ MỘT NGƯỜI ĐẠO DIỄN, BẠN QUYẾT ĐỊNH TẤT CẢ!
                    </p>
                    <p>
                        Ứng dụng được thiết kế để thể hiện rõ ràng nhất bố cục của một video hoạt hình. Bố cục bao gồm một danh sách các cảnh, mỗi cảnh có camera riêng và các thành phần con (văn bản, hình ảnh) cùng các công cụ tạo kịch bản diễn hoạt cho chúng.
                    </p>
                    <p>
                        BẠN VẼ KHÔNG ĐẸP? VẬY HÃY THỬ CÁCH "NẶN" HÌNH XEM SAO!
                    </p>
                    <p>
                        Công cụ vẽ được thiết kế nhằm mục đích đơn giản hóa thao tác vẽ, bạn sẽ thực hiện "nặn" và tô màu hình nhân vật, đồ vật hay bất cứ thứ gì bạn nghĩ ra. Bằng việc cung cấp không giới hạn các đối tượng con (có thể gọi là lớp), bạn có thể sắp xếp các thành phần một cách linh hoạt, nhanh chóng theo ý của mình.
                    </p>
                    <p>
                        TẠI SAO LOGO LẠI LÀ HÌNH MÈO?<br/>
                        Ứng dụng ra đời vào năm Quý Mão 2023 nên đặt tên như vậy cho nó cool ngầu ấy mà :))
                    </p>
                    <p>
                        Mong rằng ứng dụng có thể giúp bạn thể hiện cá tính, truyền tải thông điệp ý nghĩa đến mọi người xung quanh thông qua các tác phẩm video hoạt hình của mình {'<3'}
                    </p>

                    <h3>Liên kết</h3>

                    <div className="doc-link">
                        <a href="/ung-dung/cool-cat/huong-dan-su-dung">Hướng dẫn sử dụng</a>
                        <a href="/ung-dung/cool-cat/dieu-khoan-su-dung">Điều khoản sử dụng</a>
                        <a href="/ung-dung/cool-cat/chinh-sach-quyen-rieng-tu">Chính sách quyền riêng tư</a>
                        <a href="/ung-dung/cool-cat/xoa-tai-khoan">Hướng dẫn xóa tài khoản</a>
                    </div>

                    <div className="store-link">
                        <a href="https://play.google.com/store/apps/details?id=vn.mocalaka.animation.coolcat"><img src={Images.googlePlayBadge} /></a>
                        <a><img style={{ opacity: 0.2 }} src={Images.appleStoreBadge} /></a>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CoolCatHomeScreen;
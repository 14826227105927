import AppBar from "../appbar";
import Footer from "../footer";

interface IProps {
    bottomNavBar?: boolean;
    children?: any;
    className?: string;
    appBarHome?: boolean;
}

const Layout = ({ children, className = '', appBarHome }: IProps) => {
    return (
        <div
            className={className}
            style={{
                position: 'relative',
                minHeight: '100vh',
                paddingBottom: '100px'
            }}
        >
            <AppBar appBarHome={appBarHome} />
            <div
                style={{}}
            >
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
import { useEffect, useState } from "react";
import Layout from "../../../../components/layout"
import './styles.scss';

const AboutScreen = () => {

    return (
        <Layout>
            <div className="about-screen">
                <div className="main-content">
                    <h2>Xin chào!</h2>
                    <br />
                    <p>Chào mừng đến với <b>Mocalaka Studio!</b></p>
                    <p>
                        Mocalaka Studio là studio cá nhân của mình, tên của mình có trong source code của trang web này, các bạn có thể tìm xem :))
                        <br />
                        Cái tên Mocalaka có ý nghĩa gì? Nó là viết tắt của cụm từ "Một cách làm khác". "Một cách làm khác" là cái quéo gì?
                        <br />
                        Là cái tên mình tự nghĩ ra trong lúc đang muốn tìm một cái tên cho thương hiệu cá nhân mang hàm ý của sự sáng tạo. Hết!
                    </p>

                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', maxWidth: '100%' }}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/2lrPO_eOBGw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <br/>
                    <p>
                        Vậy mình làm nghề gì, rồi studio này làm ra để bán gì đây.
                        Bạn có thắc mắc không nhỉ? Không à? Thế để mình giới thiệu.
                    </p>
                    <p>
                        Mình học Công nghệ thông tin, ra đi làm developer đó bạn, chuyên chế cháo ra các ứng dụng phần mềm, còn gì nữa không thì chưa biết.
                        Studio này làm ra để sản xuất và quảng bá các sản phẩm cá nhân của mình, hiện tại thì đã cho ra lò một ít ứng dụng di động, tương lai chắc sẽ có tiếp, có thể sẽ có cả sản phẩm media, kiểu vậy.
                        Như tên của studio thì các sản phẩm mình làm ra sẽ theo tư tưởng một cách làm khác so với các cách làm hiện có. Ví dụ chức năng người ta làm mất phí thì mình làm miễn phí (cái này ví dụ thế thôi :v),
                        người ta dùng cách A để làm một việc gì đó thì mình nghĩ ra cách B để làm việc đó, nếu chưa có cách làm thì mình chế ra cách làm... Nói chung là để đa dạng giải pháp, đa dạng sự lựa chọn, cạnh tranh thật lực. 👍
                    </p>
                    <p>
                        Quan điểm hiện tại của mình về nghề nghiệp IT cũng như các nghề kỹ thuật là: "Kỹ thuật cũng là một nghệ thuật và người làm kỹ thuật cũng là một nghệ sĩ" :))).
                        Ai làm phần mềm mà chả chế ra được phần mềm, làm xong ngoài tự mình dùng thì còn đem ra chợ ngồi bán, rồi có quán phần mềm giống như quán trà đá; đóng gói phần mềm, thắt thêm nơ rồi đem đi tặng bạn bè, người thân, v.v...
                        Đấy, nhiều kiểu lắm, tương tự đối với các ngành nghề kỹ thuật khác.
                    </p>
                    <p>
                        Dự đoán của mình về tương lai là: lập trình trở thành kỹ năng thông thường, ai cũng biết, là nhu cầu thường nhật như ăn cơm vậy.
                        Nhà nào cũng có hệ thống IT phục vụ nhu cầu riêng. Mấy thứ như Cloud, Big data, IoT, AI,... thì cỡ như các em học sinh là đã có thể tự thiết kế, xử lý nhoay nhoáy.
                        Nghĩ đến đây là thấy phấn khởi quá đi, mong nó đến nhanh nhanh. 😆
                    </p>
                    <p>
                        Trên đây là vài lời giới thiệu của mình, thôi chào các bạn mình đi đây. 🖐
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default AboutScreen;
import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';

const CoolCatPrivacy = () => {
    return (
        <Layout>
            <div className="coolcat-privacy">
                <div className="app-info">
                    <img className="logo" src={Images.coolCatLogo} />
                    <h2>Cool Cat - Animation Maker</h2>
                </div>

                <div className="main-content">
                    <h1>Chính sách quyền riêng tư</h1>
                    <p>Cập nhật tháng 03 năm 2023</p>
                    <p>Những điều mục dưới đây mô tả cho cách mà ứng dụng Cool Cat sẽ tiến hành thu thập và sử dụng thông tin cá nhân của người dùng. Nếu bạn không đồng ý với bất kỳ điều mục nào, vui lòng không sử dụng ứng dụng này.</p>
                    <h3>Thuật ngữ</h3>
                    <ul>
                        <li>Ứng dụng, Cool Cat: ám chỉ bản thân ứng dụng được cung cấp</li>
                        <li>Chúng tôi, nhà phát triển: đại diện cho bên cung cấp dịch vụ sản phẩm ứng dụng di động Cool Cat</li>
                        <li>Bạn, người dùng: là bất kỳ ai tải và sử dụng ứng dụng của chúng tôi</li>
                        <li>Thông tin cá nhân: là những thông tin nhằm định danh một cá nhân như email, số điện thoại, số chứng minh thư, địa chỉ, …</li>
                        <li>Bên thứ ba: một bên khác không phải chúng tôi và không phải bạn</li>
                    </ul>
                    <h2>Thu thập thông tin cá nhân</h2>
                    <p>Khi sử dụng ứng dụng Cool Cat, các thông tin dưới đây có thể được yêu cầu phía người dùng cung cấp:</p>
                    <ul>
                        <li>Email</li>
                        <li>Tên, biệt danh</li>
                        <li>Ảnh đại diện</li>
                    </ul>
                    <p>Những thông tin trên được cung cấp dựa trên sự tự nguyện phía người dùng, bạn có thể đồng ý hoặc không đồng ý cung cấp những thông tin này. Nếu không đồng ý cung cấp, bạn sẽ bị giới hạn, không thể sử dụng được một số chức năng của ứng dụng.
                    </p>
                    <h2>Sử dụng thông tin cá nhân</h2>
                    <p>Những thông tin được thu thập như đề cập ở trên chỉ nhằm mục đích phục vụ bạn, đảm bảo quyền lợi của bạn khi sử dụng ứng dụng của chúng tôi.</p>

                    <p>Chúng tôi sử dụng email của bạn vào các mục đích:</p>
                    <ul>
                        <li>
                            Định danh một người dùng trong hệ thống. Chúng tôi sẽ không yêu cầu bạn cần phải xác thực địa chỉ email, việc của bạn là cần đảm bảo địa chỉ email là chính xác và còn hoạt động. Thông tin email sẽ không được hiển thị ở bất cứ đâu khác trong ứng dụng ngoài màn hình quản lý thông tin cá nhân của chính bạn.
                        </li>
                        <li>
                            Cung cấp cho bạn các khả năng thay đổi, khôi phục thông tin bảo mật tài khoản như mật khẩu truy cập ứng dụng
                        </li>
                        <li>
                            Cung cấp cho bạn các thông tin cập nhật, những hướng dẫn, cũng như thông báo trong quá trình bạn sử dụng ứng dụng
                        </li>
                        <li>
                            Phục vụ một số chức năng trong tương lai của ứng dụng mà trong đó chúng tôi sẽ gửi một kết quả, một thông tin nào đó mà bạn yêu cầu và chỉ gửi chính xác đến bạn
                        </li>
                    </ul>
                    <p>Chúng tôi sử dụng Tên, Biệt danh, Ảnh đại diện của bạn vào các mục đích:</p>
                    <ul>
                        <li>Định danh người dùng trong hệ thống. Chúng tôi không yêu cầu bạn phải cung cấp thông tin đúng, không yêu cầu bạn phải xác minh. Thông tin Tên, Biệt danh, Ảnh đại diện của bạn sẽ được dùng để hiển thị cho những người dùng khác khi bạn dùng một số chức năng của ứng dụng.
                        </li>
                    </ul>
                    <h2>Quy tắc không tiết lộ thông tin cá nhân cho bên thứ ba</h2>
                    <p>Chúng tôi sẽ không tiết lộ bất cứ thông tin cá nhân nào mà bạn cung cấp cho bên thứ ba trừ các trường hợp sau:</p>
                    <ul>
                        <li>Bạn đồng ý tiết lộ thông tin cá nhân của mình</li>
                        <li>Luật yêu cầu tiết lộ</li>
                    </ul>

                    <h2>Cập nhật chính sách quyền riêng tư</h2>
                    <p>Chính sách trên có thể sẽ thay đổi trong tương lai. Bạn cần thường xuyên cập nhật bản mới nhật tại trang này. Khi có thay đổi chúng tôi sẽ thông báo đến bạn thông qua ứng dụng hoặc email mà bạn cung cấp ở trên.</p>
                </div>
            </div>
        </Layout>
    )
}

export default CoolCatPrivacy;
import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';

const CoolCatUserGuide = () => {
    return (
        <Layout>
            <div className="coolcat-user-guide">
                <div className="app-info">
                    <img className="logo" src={Images.coolCatLogo} />
                    <h2>Cool Cat - Animation Maker</h2>
                </div>

                <div className="main-content">
                    <h1>Hướng dẫn sử dụng</h1>
                    <p>Cập nhật tháng 5 năm 2023</p>
                    <p>
                        Ứng dụng Cool Cat - Animation Maker được thiết kế với chức năng cốt lõi là tạo ra được video hoạt họa trên thiết bị di động.<br />
                        Ứng dụng hoàn toàn có thể hoạt động offline mà không ảnh hưởng đến chức năng cốt lõi.<br />
                        Bạn không cần phải đăng ký tài khoản để sử dụng, nhưng nếu bạn đăng ký thì sẽ dùng được một số chức năng thêm nữa.<br />
                        Dưới đây là những hướng dẫn giúp bạn sử dụng ứng dụng đúng như ý đồ thiết kế.
                    </p>
                    <p>
                        Loạt video hướng dẫn:<br />
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLYbn6lNq3cmXULLmG5flIy_w_jGbBuYE8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </p>

                    <h2>Giải thích thuật ngữ</h2>
                    <p>Trong ứng dụng bạn sẽ thấy các mục menu như: Hoạt họa, Ảnh nối, Lưu trữ, Cộng đồng; những mục này có ý nghĩa là:</p>
                    <ul>
                        <li>
                            <b>Hoạt họa:</b> màn hình chứa chức năng chính của ứng dụng là tạo ra video hoạt họa. Cách thức hoạt động chi tiết sẽ được trình bày ở bên dưới. Chức năng này có thể hoạt động offline, không yêu cầu đăng ký tài khoản.
                        </li>
                        <li>
                            <b>Ảnh nối:</b> cung cấp công cụ để bạn vẽ, từ đó xuất ra các file ảnh làm đầu vào cho quá trình tạo hoạt họa. Chức năng này cũng có thể hoạt động offline, không yêu cầu đăng ký tài khoản.
                        </li>
                        <li>
                            <b>Lưu trữ:</b> đây là chức năng chỉ dành cho thành viên trả phí. Khi đăng ký bạn sẽ có 10GB dung lượng lưu trữ online giúp bạn giải phóng bớt dung lượng bộ nhớ trên thiết bị, đồng thời cũng là tiền đề để có thể sử dụng được đầy đủ chức năng Cộng đồng.
                        </li>
                        <li>
                            <b>Cộng đồng:</b> như tên gọi thì đây là chức năng cho phép người dùng chia sẻ công khai các tác phẩm trong kho lưu trữ của mình với những người khác. Bạn chỉ cần đăng ký tài khoản là có thể tương tác với các tác phẩm được chia sẻ để tham khảo ý tưởng của các tác phẩm đó. Một số chức năng như thực hiện chia sẻ tác phẩm của mình hoặc sao chép tác phẩm của người khác sẽ chỉ dành riêng cho thành viên trả phí. Thành viên bình thường sẽ chỉ có thể xem và tương tác như yêu thích hoặc bình luận.
                        </li>
                    </ul>

                    <h2>Hướng dẫn chi tiết</h2>
                    <h3>1. Hoạt họa</h3>
                    <h4>1.1. Danh sách hoạt họa</h4>
                    <p>
                        Nội dung nhìn thấy ngay của mục Hoạt họa đó là danh sách các hoạt họa mà bạn đã tạo ra, để tạo họa họa mới các bạn ấn nút dấu cộng (+).
                        Mỗi tác phẩm hoạt họa trong danh sách sẽ hiển thông tin ảnh chụp và ngày cập nhật cuối.<br />
                        Ấn vào hình ảnh chụp sẽ dẫn đến màn hình chỉnh sửa tác phẩm đó. <br />
                        Ấn vào dấu 3 chấm sẽ hiện lên khung thông tin chung của tác phẩm, ở đây bạn có thể thực hiện các thao tác:
                        <ul>
                            <li>
                                Nhân đôi tác phẩm: dùng để tạo bản sao của tác phẩm đang xét
                            </li>
                            <li>
                                Xóa tác phẩm: xóa tác phẩm khỏi bộ nhớ máy. Tác phẩm tương ứng trên kho lưu trữ (nếu có) không bị ảnh hưởng.
                            </li>
                            <li>
                                Tải tác phẩm lên kho lưu trữ (chức năng này chỉ dành cho thành viên trả phí)
                            </li>
                        </ul>
                    </p>
                    <h4>1.2. Tạo, chỉnh sửa hoạt họa</h4>
                    <p>
                        Màn hình này bao gồm các thành phần:
                        <ul>
                            <li>
                                Menu hành động (trên cùng bên phải) cung cấp các thao tác:
                                <ul>
                                    <li>
                                        Lưu tác phẩm: lưu các chỉnh sửa hiện tại của bạn trên bộ nhớ máy
                                    </li>
                                    <li>
                                        Cài đặt / Xuất: ở đây bạn có thể chỉnh sửa độ phân giải đầu ra, tốc độ khung hình, và tiến hành xuất tác phẩm thành file video mp4
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Không gian hiển thị: vùng không gian này hiển thị các hình ảnh của các đối tượng trong tác phẩm hoạt họa của bạn
                            </li>
                            <li>
                                Điều khiển phát: bạn có thể điều khiển phát xem trước cảnh hiện tại hoặc toàn bộ tác phẩm
                            </li>
                            <li>
                                Các thành phần trong cảnh: bao gồm các đối tượng hình ảnh và văn bản trong một cảnh cụ thể, đối tượng luôn ở đầu tiên là đối tượng camera của cảnh đó.
                                Bạn có thể thêm đối tượng, sửa các thuộc tính của đối tượng, thiết lập kịch bản điều khiển đối tượng.
                            </li>
                            <li>
                                Danh sách các cảnh: danh sách toàn bộ cảnh của tác phẩm. Bạn có thể thêm cảnh mới, sửa thuộc tính của cảnh, nhân đôi, xóa cảnh.
                            </li>
                        </ul>
                    </p>

                    <h3>2. Ảnh nối</h3>
                    <p>
                        Sử dụng công cụ này bạn có thể tạo ra các ảnh đơn, hoặc ảnh nối từ nhiều ảnh đơn (ý tưởng tương tự như diễn hoạt frame by frame) để làm đầu vào cho tác phẩm hoạt họa của mình.
                        <br />
                        Cách tổ chức màn hình cũng tương tự như mục Hoạt họa gồm các thành phần:
                    </p>
                    <h4>2.1. Danh sách ảnh nối</h4>
                    <p>Các chức năng tương tự như mục Hoạt họa</p>

                    <h4>2.2. Tạo, chỉnh sửa ảnh nối</h4>
                    <p>
                        Màn hình này bao gồm các thành phần:
                        <ul>
                            <li>
                                Menu hành động (trên cùng bên phải) cung cấp các thao tác:
                                <ul>
                                    <li>
                                        Lưu tác phẩm: lưu các chỉnh sửa hiện tại của bạn trên bộ nhớ máy
                                    </li>
                                    <li>
                                        Xuất ảnh đang hiển thị: xuất hình ảnh đang xét dưới dạng ảnh đơn
                                    </li>
                                    <li>
                                        Xuất ảnh nối: ghép tất cả các ảnh đang có theo hàng ngang và xuất thành một ảnh
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Không gian hiển thị: vùng không gian này hiển thị các hình ảnh của các đối tượng trong tác phẩm của bạn
                            </li>
                            <li>
                                Thanh công cụ: hiện tại đang có 2 loại thanh công cụ phục vụ cho thao tác vẽ tay và chỉnh sửa đường nối (path)
                            </li>
                            <li>
                                Các thành phần con: một hình ảnh sẽ có thể được cấu thành từ các đối tượng nhỏ hơn như là hình ảnh, văn bản, vẽ tay, đường nối.
                            </li>
                            <li>
                                Danh sách hình ảnh: danh sách toàn bộ các hình ảnh trong tác phẩm của bạn.
                            </li>
                        </ul>
                    </p>

                    <h3>3. Lưu trữ</h3>
                    <p>Chức năng lưu trữ tác phẩm của bạn trên kho lưu trữ online, giúp giải phóng bộ nhớ máy, dành riêng cho thành viên trả phí.</p>
                    <h4>3.1. Đối với thành viên thông thường</h4>
                    <p>
                        Màn hình sẽ hiển thị nội dung các lợi ích của việc đăng ký thành viên trả phí, nếu bạn thấy có nhu cầu thì ấn nút Nâng cấp lên Premium.
                        <br />
                        Một khung thông tin sẽ hiện lên tùy chọn để bạn đăng ký. Hình thức đăng ký ở đây là hình thức trả phí hằng tháng. Số tiền đăng ký sẽ dùng để thuê dung lượng lưu trữ và duy trì chức năng Cộng đồng.
                        <br />
                        Phương thức đăng ký sẽ dùng của Google Play (đối với ứng dụng Android) và App Store (đối với ứng dụng iOS). Bạn chỉ cần đăng ký bằng một trong hai cách đã nêu là có thể sử dụng được ở các thiết bị khác nhau (cả Android và iOS).
                        <br />
                        Toàn bộ các các thao tác thanh toán, trừ tiền hằng tháng đều thông qua hai nhà cung cấp trên. Tuy nhiên nếu gặp phải lỗi không mong muốn (ví dụ như đã thanh toán rồi mà hệ thống không ghi nhận), bạn vui lòng liên hệ với bộ phận hỗ trợ của ứng dụng thông qua email: mocalaka.studio@gmail.com
                    </p>
                    <h4>3.2. Đối với thành viên trả phí</h4>
                    <p>
                        Màn hình sẽ hiển thị các nội dung sau:
                        <ul>
                            <li>
                                <b>Dung lượng đã sử dụng</b>: thông tin dung lượng mà bạn đã sử dụng để lưu trữ các tác phẩm của mình.
                            </li>
                            <li>
                                <b>Danh sách tác phẩm</b>: hiển thị các tác phẩm bạn đã tải lên. Bạn có thể thực hiện các thao tác như xem, sửa thông tin, tải về máy hoặc xóa các tác phẩm này.
                                Khi chỉnh sửa thông tin tác phẩm, nếu bạn tích chọn vào ô Công khai, tác phẩm sẽ được hiển thị công khai trong màn hình Cộng đồng cho mọi người xem.
                                Nếu bạn tích chọn vào ô Cho phép sao chép, những người dùng trả phí khác có thể tạo bản sao tác phẩm của bạn vào kho lưu trữ của họ.
                                Việc này giúp cho họ có thể biết được chi tiết cách bạn tạo ra tác phẩm đó, cũng là ý nghĩa chia sẻ gốc của chức năng Cộng đồng.
                            </li>
                        </ul>

                    </p>

                    <h3>4. Cộng đồng</h3>
                    <p>Chức năng giúp bạn quảng bá tác phẩm của mình. <br /> Màn hình bao gồm các mục:</p>
                    <ul>
                        <li>
                            <b>Mới</b>: đây là các tác phẩm mới được chia sẻ, sắp xếp theo thời gian chia sẻ, mới hiện trước, cũ hiện sau.
                        </li>
                        <li>
                            <b>Xu hướng</b>: hiển thị các tác phẩm mới nhất trong vòng 15 ngày đổ lại, sắp xếp theo số lượt yêu thích (thả tim)
                        </li>
                        <li>
                            <b>Xếp hạng tuần</b>: hiển thị các tác phẩm được chia sẻ trong tuần hiện tại, sắp xếp theo số lượt yêu thích.
                        </li>
                        <li>
                            <b>Đánh dấu sao</b>: danh sách các tác phẩm mà bạn đánh dấu sao, dùng để xem lại vào lúc khác.
                        </li>
                        <li>
                            <b>Tìm kiếm theo từ khóa</b>: bạn có thể tìm kiếm các tác phẩm được chia sẻ theo từ khóa chỉ định.
                        </li>
                    </ul>
                    <p>
                        Trong màn hình chi tiết tác phẩm, bạn có thể thực hiện các thao tác phát, yêu thích, bình luận, đánh dấu sao, thêm vào kho lưu trữ, báo cáo (trong trường hợp bạn cho rằng tác phẩm đã vi phạm quy định về nội dung).
                    </p>

                    <h2>Cuối cùng</h2>
                    <p>
                        Trên đây là các hướng dẫn các bạn sử dụng ứng dụng tạo hoạt họa Cool Cat - Animation Maker. Nếu bạn thấy còn thiếu mục nào hoặc cần làm rõ hơn ở đâu, vui lòng gửi email về bộ phận hỗ trợ để chúng mình cập nhật thêm.
                        <br />
                        <b>Chúc các bạn sử dụng ứng dụng vui vẻ, tạo được nhiều tác phẩm hay ho ❤️</b>
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default CoolCatUserGuide;
import Images from "../../../../../assets/images";
import Layout from "../../../../../components/layout"
import './styles.scss';

const CoolCatTerm = () => {
    return (
        <Layout>
            <div className="coolcat-privacy">
                <div className="app-info">
                    <img className="logo" src={Images.coolCatLogo} />
                    <h2>Cool Cat - Animation Maker</h2>
                </div>

                <div className="main-content">
                    <h1>Điều khoản sử dụng</h1>
                    <p>Cập nhật tháng 03 năm 2023</p>
                    <p>Các điều khoản này nhằm định hướng cho bạn cách sử dụng ứng dụng Cool Cat - Animation Maker. Việc sử dụng ứng dụng của chúng tôi đồng nghĩa với việc bạn đồng ý với bản Điều khoản sử dụng này. Nội dung của điều khoản có thể được thay đổi theo từng thời điểm, phụ thuộc vào tình hình sử dụng thực tế. Nếu bạn không đồng ý với bất kỳ điều mục nào, vui long không sử dụng ứng dụng này.</p>
                    <h3>Thuật ngữ</h3>
                    <ul>
                        <li>Ứng dụng, Cool Cat: ám chỉ bản thân ứng dụng được cung cấp</li>
                        <li>Chúng tôi, nhà phát triển: đại diện cho bên cung cấp dịch vụ sản phẩm ứng dụng di động Cool Cat</li>
                        <li>Bạn, người dùng: là bất kỳ ai tải và sử dụng ứng dụng của chúng tôi</li>
                        <li>Thông tin cá nhân: là những thông tin nhằm định danh một cá nhân như email, số điện thoại, số chứng minh thư, địa chỉ, …</li>
                        <li>Bên thứ ba: một bên khác không phải chúng tôi và không phải bạn</li>
                    </ul>
                    <h2>Đăng ký tài khoản</h2>
                    <p>Tất cả mọi người đều có thể đăng ký tài khoản để sử dụng ứng dụng. Khi đăng ký tài khoản, tên tài khoản, ảnh đại diện hoặc thông tin cá nhân mà bạn cung cấp phải tuân thủ theo quy định về Chính sách nội dung của chúng tôi.</p>

                    <h2>Quyền lợi và trách nhiệm của người sử dụng ứng dụng</h2>
                    <ul>
                        <li>
                            Được quyền yêu cầu hỗ trợ, hướng dẫn, giải đáp những thắc mắc, khó khăn khi sử dụng ứng dụng
                        </li>
                        <li>
                            Khi phát hiện ra lỗi của ứng dụng hoặc bất cứ hành vi vi phạm nào của người dùng khác, hãy sử dụng chức năng báo cáo hoặc thông báo cho chúng tôi qua email
                        </li>
                        <li>
                            Không sử dụng ứng dụng vào các mục đích bất hợp pháp, bị cấm theo quy định của pháp luật hoặc vi phạm các quyền hợp pháp, gây trở ngại, hạn chế việc sử dụng ứng dụng của người dùng khác
                        </li>
                    </ul>
                    <h2>Quyền và trách nhiệm của chúng tôi</h2>
                    <ul>
                        <li>
                            Thiết lập biện pháp kỹ thuật và quy trình quản lý hoạt động, được quyền yêu cầu người dùng tuân thủ, chấp hành các quy định của chúng tôi
                        </li>
                        <li>
                            Được quyền thu thập, lưu trữ các thông tin của người dùng theo quy định và phục vụ mục đích điều hành, chăm sóc và hỗ trợ người dùng
                        </li>
                        <li>
                            Được quyền kiểm duyệt, ngăn chặn, loại bỏ những nội dung không phù hợp theo quy định của chúng tôi và quy định pháp luật
                        </li>
                        <li>
                            Hỗ trợ, hướng dẫn, giải đáp những thắc mắc, khó khăn cho người dùng khi sử dụng ứng dụng. Giải quyết những báo cáo của người dùng gửi đến
                        </li>
                    </ul>

                    <h2>Cơ chế xử lý vi phạm của người dùng</h2>
                    <p>Nếu bạn vi phạm điều khoản được nêu ra ở tài liệu này, tùy vào mức độ vi phạm, chúng tôi sẽ có các hành động xử lý thích hợp.</p>
                    <p>Các hình thức xử lý vi phạm của chúng tôi:</p>
                    <ul>
                        <li>
                            Nhắc nhở đối với những vi phạm lần đầu, vi phạm không nghiêm trọng
                        </li>
                        <li>
                            Xóa bài đăng vi phạm
                        </li>
                        <li>
                            Xóa tài khoản nếu vi phạm nhiều lần, vi phạm nghiêm trọng và sẽ thông báo rộng rãi đến những người dùng khác thông qua các kênh liên lạc hiện có
                        </li>
                    </ul>

                    <h2>Chính sách nội dung</h2>
                    <p>Các thông tin cấm đăng tải, trao đổi:</p>
                    <ul>
                        <li>
                            Thông tin chống lại Nhà Nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam
                        </li>
                        <li>
                            Tuyên truyền chiến tranh, khủng bố.
                        </li>
                        <li>
                            Xúi giục, lôi kéo, kích động người khác phạm tội.
                        </li>
                        <li>
                            Xuyên tạc lịch sử, phủ nhận thành tựu cách mạng, phá hoại khối đại đoàn kết toàn dân tộc, xúc phạm tôn giáo, phân biệt đối xử về giới, phân biệt chủng tộc, tôn giáo; gây hận thù, mâu thuẫn giữa các dân tộc, sắc tộc, tôn giáo.
                        </li>
                        <li>
                            Thông tin kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ nạn xã hội, mê tín dị đoan, phá hoại thuần phong, mỹ tục của dân tộc.
                        </li>
                        <li>
                            Tiết lộ bí mật nhà nước, bí mật quân sự, an ninh, kinh tế, đối ngoại và những bí mật khác do pháp luật Việt Nam quy định.
                        </li>
                        <li>
                            Thông tin xuyên tạc, vu khống, xúc phạm uy tín của tổ chức, danh dự và nhân phẩm của cá nhân.
                        </li>
                        <li>
                            Quảng cáo, tuyên truyền, mua bán hàng hóa, dịch vụ bị cấm; truyền bá tác phẩm báo chí, văn học, nghệ thuật, xuất bản phẩm bị cấm.
                        </li>
                        <li>
                            Giả mạo tổ chức, cá nhân và phát tán thông tin giả mạo, thông tin sai sự thật xâm hại đến quyền và lợi ích hợp pháp của tổ chức, cá nhân.
                        </li>
                        <li>
                            Thông tin vi phạm quyền sở hữu trí tuệ.
                        </li>
                        <li>
                            Các Thông tin khác vi phạm tới quyền và lợi ích hợp pháp của cá nhân, tổ chức.
                        </li>
                        <li>
                            Thông tin có tính chất gây nguy hại cho trẻ em, xâm hại đến trẻ em và quyền trẻ em
                        </li>
                    </ul>

                    <h2>Cập nhật điều khoản sử dụng</h2>
                    <p>Điều khoản sử dụng này có thể sẽ thay đổi trong tương lai. Bạn cần thường xuyên cập nhật bản mới nhật tại trang này. Khi có thay đổi chúng tôi sẽ thông báo đến bạn thông qua ứng dụng hoặc email mà bạn cung cấp.</p>
                </div>
            </div>
        </Layout>
    )
}

export default CoolCatTerm;
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Images from '../../assets/images';
import './styles.scss';

interface IProps {
    children?: any;
    appBarHome?: boolean;
}

const AppBar = ({ children, appBarHome }: IProps) => {
    const navigate = useNavigate();

    return (
        <Navbar sticky="top" className={appBarHome ? "app-bar appBarHome" : "app-bar"} bg={appBarHome ? 'none' : 'light'} variant={appBarHome ? 'dark' : 'light'} expand="lg" style={{
            position: appBarHome ? 'fixed' : 'sticky',
            width: '100%'
        }}>
            <Container>
                <Navbar.Brand onClick={() => navigate('/')}>
                    <img style={{ height: '45px', cursor: 'pointer' }} src={Images.logoMain} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" activeKey={window.location.pathname}>
                        <Nav.Link href="/gioi-thieu">Giới thiệu</Nav.Link>
                        <Nav.Link href="/ung-dung">Ứng dụng</Nav.Link>
                        <Nav.Link href="/khuyen-mai">Khuyến mãi</Nav.Link>
                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AppBar;
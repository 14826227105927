import './styles.scss'
import Images from '../../assets/images';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='brand'>
                    <img src={Images.logoWhite} />
                    <p>Mocalaka Studio &copy;2023</p>
                </div>
                <div className='sns'>
                    <a target='_blank' href='https://www.youtube.com/@MocalakaStudio'><img src={Images.iconYoutube} /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './modules/home/screens/home';
import CoolCatPrivacy from './modules/home/screens/coolcat/privacy';
import NotFound from './modules/home/screens/NotFound';
import CoolCatTerm from './modules/home/screens/coolcat/term';
import CoolCatUserGuide from './modules/home/screens/coolcat/UserGuide';
import CoolCatUserLeave from './modules/home/screens/coolcat/UserLeave';
import CoolCatUserLeaveDone from './modules/home/screens/coolcat/UserLeaveDone';
import AboutScreen from './modules/home/screens/about';
import AppsScreen from './modules/home/screens/apps';
import CoolCatHomeScreen from './modules/home/screens/coolcat/home';
import NoLedHomeScreen from './modules/home/screens/noled/home';
import PromotionScreen from './modules/home/screens/promotion';

const router = createBrowserRouter([
  {
    id: 'Home',
    path: "/",
    element: <Home />,
  },
  {
    id: 'AboutScreen',
    path: "/gioi-thieu",
    element: <AboutScreen />,
  },
  {
    id: 'AppsScreen',
    path: "/ung-dung",
    element: <AppsScreen />,
  },
  {
    id: 'CoolCatHomeScreen',
    path: "/ung-dung/cool-cat",
    element: <CoolCatHomeScreen />,
  },
  {
    id: 'CoolCatPrivacy',
    path: "/ung-dung/cool-cat/chinh-sach-quyen-rieng-tu",
    element: <CoolCatPrivacy />,
  },
  {
    id: 'CoolCatTerm',
    path: "/ung-dung/cool-cat/dieu-khoan-su-dung",
    element: <CoolCatTerm />,
  },
  {
    id: 'CoolCatUserGuide',
    path: "/ung-dung/cool-cat/huong-dan-su-dung",
    element: <CoolCatUserGuide />,
  },
  {
    id: 'CoolCatUserLeave',
    path: "/ung-dung/cool-cat/xoa-tai-khoan",
    element: <CoolCatUserLeave />,
  },
  {
    id: 'CoolCatUserLeaveDone',
    path: "/ung-dung/cool-cat/cam-on-ban",
    element: <CoolCatUserLeaveDone />,
  },
  {
    id: 'NoLedHomeScreen',
    path: "/ung-dung/no-led",
    element: <NoLedHomeScreen />,
  },
  {
    id: 'PromotionScreen',
    path: "/khuyen-mai",
    element: <PromotionScreen />,
  },
  {
    id: 'NotFound',
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
